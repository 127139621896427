import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  VStack,
  Checkbox,
} from "@chakra-ui/react";

import { Dialog } from "../../components/Dialog";
import Button from "../../components/Button";
import { P, Large } from "../../components/Type";
import LoginForm from "../LoginForm";
import { useLocalStorage } from "react-use";

const LoginMode = ({ onSuccess }) => {
  return <LoginForm onSuccess={onSuccess} />;
};

const LoginHelperModal = ({ userInfo, mode, setMode }) => {
  const [ignoring, setIgnoring] = React.useState(true);
  const [dontRemindLogin, setDontRemindLogin] = useLocalStorage(
    "dont-remind-login",
    true
  );

  const onClose = () => {
    setMode("ignore");
    if (ignoring) {
      setDontRemindLogin(true);
    }
  };

  if (mode === "login") {
    return (
      <Modal isOpen={true} size={"lg"} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <Dialog>
            <ModalHeader>
              <Large mb={0}>Login</Large>
            </ModalHeader>
            <ModalBody>
              <LoginMode onSuccess={onClose} />
            </ModalBody>
          </Dialog>
        </ModalContent>
      </Modal>
    );
  }

  if (Object.keys(userInfo).length || mode === "ignore" || dontRemindLogin) {
    return null;
  }

  return (
    <Modal isOpen={true} size={"lg"} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Dialog>
          <ModalHeader>
            <Large mb={0}>
              {mode === "login" ? "Login" : "Kamu belum login"}
            </Large>
          </ModalHeader>
          <ModalBody>
            {mode === "login" ? (
              <LoginMode />
            ) : (
              <VStack spacing="2em">
                <P>
                  Login untuk menyimpan semua histori dukungan dan tampil beda
                  pada alert notifikasi!
                </P>
                <Checkbox
                  alignSelf="start"
                  borderColor={"black"}
                  value={ignoring}
                  onChange={() => {
                    setIgnoring(!ignoring);
                  }}
                >
                  Jangan tampilkan lagi.
                </Checkbox>
                <HStack justifyContent="space-between" w="100%" spacing={"2em"}>
                  <Button
                    marginBottom="0"
                    bgColor="saweria-yellow.normal"
                    onClick={(e) => {
                      e.preventDefault();
                      onClose();
                    }}
                    label="Gak dulu"
                    flexGrow={1}
                  />
                  <Button
                    flexGrow={1}
                    display="block"
                    onClick={(e) => {
                      e.preventDefault();
                      setMode("login");
                    }}
                    label="Login"
                    bgColor="saweria-green.normal"
                  />
                </HStack>
              </VStack>
            )}
          </ModalBody>
        </Dialog>
      </ModalContent>
    </Modal>
  );
};

export { LoginHelperModal };
