import React, { useState } from "react";
import dynamic from "next/dynamic";

import { Formik, Field, Form } from "formik";
import { object, string, number } from "yup";
import { FormattedNumberInput, Input } from "../components/Input";

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox,
  Flex,
} from "@chakra-ui/react";
import Loader from "../components/Loader";
import useDonation from "../hooks/useDonations";
import { formatMoney, getMaxTip, getMinTip, VENDOR_CUT } from "../utils/";
import { Large, P } from "../components/Type";
import Link from "../components/Link";
import getVideoId from "get-video-id";
import { UsernameBadge } from "../components/Username";
import isEmail from "validator/lib/isEmail";
import { Trans, msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { PaymentMethods } from "./PaymentMethods";
import { getFormError } from "../i18n";
import { SoundboardSelector } from "./StreamerPage/MediaSelector/SoundboardSelector";
import { PaymentMethodSelectorProvider, usePaymentMethodSelector } from "../contexts/paymentMethodSelector";
import Button from "../components/Button";
import { useRouter } from "next/router";

const baseYupModel = {
  from: string()
    .required(msg`Nama tidak boleh kosong`)
    .max(32, msg`Nama terlalu panjang`)
    .test(
      "not-readable-domain",
      (message) => msg`Nama tidak diperbolehkan`,
      async (value = "") => {
        return await !isReadableAsDomain(value);
      }
    ),

  message: string()
    .notRequired(msg`Pesan tidak boleh kosong`)
    .max(140),
  ytStart: number().moreThan(-1, msg`Tidak boleh negatif`),
  email: string()
    .required(msg`Masukkan email kamu`)
    .email(msg`Masukan email kamu`)
    .test(
      "is-valid",
      (message) => msg`Periksa lagi email kamu`,
      async (value = "") => {
        return await isEmail(value);
      }
    ),
  phone: string(),
};

const getYupSchema = (currency) => {
  const MINIMUM_TIP_AMOUNT = getMinTip(currency);
  const MAXIMUM_TIP_AMOUNT = getMaxTip(currency);

  return object().shape({
    ...baseYupModel,
    amount: number()
      .required(msg`Masukkan nominal yang ingin diberikan`)
      .min(
        MINIMUM_TIP_AMOUNT,
        msg`Nominal minimum ${formatMoney(MINIMUM_TIP_AMOUNT, {
          currency,
        })}`
      )
      .max(
        MAXIMUM_TIP_AMOUNT,
        msg`Nominal maksimum ${formatMoney(MAXIMUM_TIP_AMOUNT, {
          currency,
        })}`
      ),
  });
};

function isReadableAsDomain(str) {
  // Remove all white spaces
  var noSpaces = str.replace(/\s/g, "");

  if (noSpaces.indexOf("http") >= 0 || noSpaces.indexOf(":/") >= 0) {
    return true;
  }

  // Use regex to check for domain name format
  var domainRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/;

  return domainRegex.test(noSpaces.toLowerCase());
}

const phoneRegex = new RegExp("^08\\d{7,11}$");

const SoundboardForm = ({
  plus,
  overlay,
  vote,
  streamerId,
  isGopayEnabled,
  isOvoEnabled,
  isDanaEnabled,
  isLinkAjaEnabled,
  isQRISEnabled,
  currUsername = "",
  currEmail = "",
  currency,
  transactionFeePolicy,
}) => {
  const { createDonation, calculateAmountToPay } = useDonation();
  const yupSchema = getYupSchema(currency);
  const { soundboard_items } = overlay;
  const { _ } = useLingui();
  const router = useRouter();

  const [selectedSoundboardItem, setSelectedSoundboardItem] = useState({});

  return (
    <PaymentMethodSelectorProvider>
      <Formik
        validationSchema={yupSchema}
        initialValues={{
          agree: false,
          notUnderage: false,
          from: currUsername,
          message: "",
          amount: "",
          payment_type: "",
          email: currEmail,
          phone: "",
          vote: "",
          giphy: null,
          yt: "",
          ytStart: 0,
          mediaType: null,
          currency,
          soundboard: null,
          amountToPay: ""
        }}
        enableReinitialize
        validate={(val) => {
          const error = {};

          if (val.payment_type === "ovo") {
            if (!phoneRegex.test(val.phone)) {
              error["phone"] = msg`Masukkan nomor yg benar`;
            }
            if (!val.phone) {
              error[
                "phone"
              ] = msg`Masukkan nomor telepon untuk pembayaran ${val.payment_type.toUpperCase()}`;
            }
          }
          if (!val.notUnderage) {
            error["notUnderage"] = msg`Anda harus berusia 17 tahun atau lebih.`;
          }
          if (!val.agree) {
            error[
              "agree"
            ] = msg`Anda harus menyetujui syarat dan ketentuan saweria.co`;
          }
          if (val.mediaType === "sb") {
            const { soundboard } = val;
            if (!soundboard) {
              error["soundboard"] = msg`Soundboard harus dipilih`;
            }
          }

          return error;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await createDonation(streamerId, values);
          } catch (error) {
            console.log(error);
          }
          setSubmitting(false);
        }}
      >
        {({
          setFieldValue,
          submitForm,
          isSubmitting,
          values,
          setFieldError,
          errors,
        }) => {
          const { _ } = useLingui();
          const donate = (type) => async () => {
            await setFieldValue("payment_type", type, false);
            submitForm();
          };
            const onClickPaymentMethod = (type) => async () => {
              setSelectedPaymentMethod(type);
              await setFieldValue("payment_type", type, false);
              const { username } = router.query;
              const amountToPayRes = await calculateAmountToPay(username, {
                ...values,
                payment_type: type,
              });
              await setFieldValue("amountToPay", amountToPayRes);
            };


          const { selectedPaymentMethod, setSelectedPaymentMethod, setTransactionFeePolicy } = usePaymentMethodSelector()
          setTransactionFeePolicy(transactionFeePolicy)

          if (isSubmitting) {
            return <Loader />;
          }

          const allowPayment = !Object.keys(errors).length && values.agree;

          return (
            <Form>
              <Field type="text" name="soundboard">
                {({ field, form }) => {
                  return (
                    <FormControl
                      isRequired
                      mb={3}
                      isInvalid={
                        form.errors.soundboard && form.touched.soundboard
                      }
                    >
                      <FormLabel htmlFor="message">
                        <Trans>Pilih Suara:</Trans>
                      </FormLabel>
                      <SoundboardSelector
                        setFieldValue={setFieldValue}
                        values={values}
                        mode={"tipper"}
                        overlay={overlay}
                        soundboardData={soundboard_items}
                        selectedSoundboardItem={selectedSoundboardItem}
                        setSelectedSoundboardItem={setSelectedSoundboardItem}
                      />
                      <FormErrorMessage>
                        {getFormError(form.errors.soundboard, _)}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              <Field type="text" name="from">
                {({ field, form }) => {
                  return (
                    <FormControl
                      isRequired
                      isReadOnly={currUsername}
                      mb={3}
                      isInvalid={form.errors.from && form.touched.from}
                    >
                      <FormLabel htmlFor="from">
                        <Trans>Dari:</Trans>
                      </FormLabel>
                      {currUsername ? (
                        <UsernameBadge
                          w={"min-content"}
                          username={currUsername}
                        ></UsernameBadge>
                      ) : (
                        <Input
                          {...field}
                          data-testid="from-input"
                          id="from"
                          placeholder={_(msg`Budi`)}
                        />
                      )}
                      <FormErrorMessage>
                        {getFormError(form.errors.from, _)}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              <Field name="email">
                {({ field, form }) => {
                  return (
                    <FormControl
                      display={currEmail ? "none" : "block"}
                      isRequired
                      isReadOnly={currEmail}
                      mb={3}
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel htmlFor="email">Email:</FormLabel>
                      <Input
                        {...field}
                        data-testid="email-input"
                        type="email"
                        id="email"
                        placeholder={_(msg`budi@saweria.co`)}
                      />
                      <FormErrorMessage>
                        {getFormError(form.errors.email, _)}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              <Field type="text" name="message">
                {({ field, form }) => {
                  return (
                    <FormControl
                      mb={3}
                      isInvalid={form.errors.message && form.touched.message}
                    >
                      <FormLabel htmlFor="message">
                        <Trans>Pesan:</Trans>
                      </FormLabel>
                      <Input
                        {...field}
                        data-testid="message-input"
                        id="message"
                        placeholder={_(msg`Selamat pagi`)}
                      />
                      <FormErrorMessage>
                        {getFormError(form.errors.message, _)}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              {["ovo"].includes(values.payment_type) ? (
                <>
                  <Field name="phone">
                    {({ field, form }) => {
                      return (
                        <FormControl
                          isRequired
                          mb={3}
                          isInvalid={form.errors.phone && form.touched.phone}
                        >
                          <FormLabel htmlFor="phone">Nomor telepon:</FormLabel>
                          <Input
                            {...field}
                            type="tel"
                            data-testid="phone-input"
                            id="phone"
                            placeholder={_(
                              msg`Nomor yang terdaftar di e-wallet pilihan`
                            )}
                          />
                          <FormErrorMessage>
                            {getFormError(form.errors.phone, _)}
                          </FormErrorMessage>
                        </FormControl>
                      );
                    }}
                  </Field>
                </>
              ) : null}
              <Field name="notUnderage">
                {({ field, form }) => {
                  return (
                    <FormControl
                      isRequired
                      mb="3"
                      isInvalid={
                        form.errors.notUnderage && form.touched.notUnderage
                      }
                    >
                      <Checkbox
                        data-testid="underage-checkbox"
                        size="lg"
                        {...field}
                        isInvalid={
                          form.errors.notUnderage && form.touched.notUnderage
                        }
                        borderColor="black"
                      >
                        <P fontSize="0.75em" mb={0}>
                          <Trans>Saya berusia 17 tahun atau lebih</Trans>
                        </P>
                      </Checkbox>

                      <FormErrorMessage>
                        {getFormError(form.errors.notUnderage, _)}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              <Field name="agree">
                {({ field, form }) => {
                  return (
                    <FormControl
                      isRequired
                      mb="3"
                      isInvalid={form.errors.agree && form.touched.agree}
                    >
                      <Checkbox
                        data-testid="agree-checkbox"
                        size="lg"
                        {...field}
                        isInvalid={form.errors.agree && form.touched.agree}
                        borderColor="black"
                      >
                        <P fontSize="0.75em" mb={0}>
                          <Trans>
                            Saya setuju bahwa dukungan ini diberikan secara
                            sukarela dan bukan sebagai imbalan atas kegiatan
                            komersial, sesuai dengan{" "}
                            <Link textDecoration="underline" href="/terms">
                              {`syarat dan ketentuan saweria.co`}
                            </Link>
                          </Trans>
                        </P>
                      </Checkbox>

                      <FormErrorMessage>
                        {getFormError(form.errors.agree, _)}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>
                <PaymentMethods
                  currency={currency}
                  isSubmitting={isSubmitting}
                  allowPayment={allowPayment}
                  enabledPayments={{
                    isDanaEnabled,
                    isQRISEnabled,
                    isGopayEnabled,
                    isLinkAjaEnabled,
                    isOvoEnabled,
                  }}
                  donate={onClickPaymentMethod}
                />
                <Flex justifyContent="space-between" marginTop={6}>
                  <Flex flexDirection="column">
                    {transactionFeePolicy === 'TIPPER' &&
                      <>
                        <Large fontSize={["md"]}>
                          <Trans>Jumlah Dukungan: </Trans> {formatMoney(values.amount, {currency})}
                        </Large>
                        <Large fontSize={["sm"]}>
                          <Trans>Biaya Layanan (Payment Gateway):</Trans> {VENDOR_CUT[values.payment_type]}
                        </Large>
                      </>
                    }
                    <Large>
                      <Trans>Total</Trans> {formatMoney(values.amountToPay, {currency})}
                    </Large>
                  </Flex>
                  <Flex>
                    <Button
                      marginBottom={0}
                      disabled={!allowPayment && selectedPaymentMethod}
                      data-testid="donate-button"
                      backgroundColor={`saweria-yellow.${allowPayment && selectedPaymentMethod
                          ? "normal"
                          : "dark"
                        }`}
                      type="button"
                      label={t`Kirim Dukungan`}
                      onClick={submitForm}
                    />
                  </Flex>
                </Flex>
 
            </Form>
          );
        }}
      </Formik>
    </PaymentMethodSelectorProvider>
  );
};

export { SoundboardForm };
