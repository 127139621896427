import React from "react";

import { Formik, Field, Form } from "formik";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  SimpleGrid,
  Flex,
  useToast,
} from "@chakra-ui/react";
import Link from "../components/Link";
import { Input, PasswordInput } from "../components/Input";
import * as Yup from "yup";
import Button from "../components/Button";
import { useRequest } from "../contexts/request";
import { useMutation } from "react-query";
import { invalidateUserInfo } from "../hooks/useUserInfo";
import { Trans, msg, t } from "@lingui/macro";
import { getFormError } from "../i18n";
import { useLingui } from "@lingui/react";

const getFormValidation = (otp) => {
  const schema = {
    email: Yup.string()
      .email(msg`Masukkan email yang valid`)
      .required(msg`Email tidak boleh kosong`),
    password: Yup.string()
      .min(6, msg`Password harus lebih dari 6`)
      .required(msg`Password harus terisi`),
  };

  if (otp) {
    return Yup.object({
      ...schema,
      otp: Yup.string()
        .required(msg`Kode 2FA tidak boleh kosong`)
        .matches(new RegExp("^[0-9]{6}$"), msg`Masukan 6 digit angka`),
    });
  }

  return Yup.object({ ...schema });
};

const LoginForm = ({ onSuccess = () => {} }) => {
  const { login, post, setUserInfo } = useRequest();

  const [needOTP, setNeedOTP] = React.useState(false);
  const { _ } = useLingui();

  const toast = useToast();

  const [disableTOTP, { isLoading }] = useMutation(
    async (values) => {
      await post("/auth/disable_totp", { json: { ...values } });
    },
    {
      onSuccess: () => {
        toast({
          title: t`Proses mematikan 2FA berhasil`,
          description: t`Silahkan cek email untuk melanjutkan proses.`,
          isClosable: true,
          status: "success",
        });
      },
      onError: (error) => {
        const { response } = error;
        let title = t`Gagal mematikan 2FA`;
        let description = t`Ada kesalah pada sistem kami`;
        let status = "error";

        if (!response) {
          toast({
            title,
            description,
            isClosable: true,
            status: status,
          });
          return;
        }

        switch (response.json.message) {
          case "TOTP_DISABLED":
            description = t`Akun ini belum memasang 2FA.`;
          case "Email Provider fails to receive request":
            description = t`Tidak dapat mengirim email konfirmasi. Silahkan Coba lagi.`;
        }

        toast({
          title,
          description,
          isClosable: true,
          status: status,
        });
      },
    }
  );

  const [submitLogin] = useMutation(login, {
    onSuccess: ({ json, headers }) => {
      const { data } = json;
      const token = headers.get("Authorization");
      setUserInfo({ ...data, token });
      onSuccess();
      invalidateUserInfo();
    },
    onError: (error) => {
      const { response } = error;
      let title = t`Login gagal`;
      let description = t`Ada kesalah pada sistem kami`;
      let status = "error";

      if (!response) {
        toast({
          title,
          description,
          isClosable: true,
          status: status,
        });
        return;
      }

      switch (response.json.message) {
        case "EMAIL_OR_PASSWORD_INVALID":
          description = t`Email atau password salah`;
          break;
        case "OTP_REQUIRED":
          setNeedOTP(true);
          title = t`Butuh 2FA token`;
          description = t`Silahkan masukkan 2FA token`;
          status = "info";
          break;
        case "OTP_INVALID":
          title = t`2FA token salah`;
          description = t`Silahkan masukkan 2FA token yang benar`;
          break;
        case "USER_SUSPENDED":
          description = t`Akun saweriamu telah dinonaktifkan (suspended) oleh sistem. Silahkan menghubungi help@saweria.co untuk informasi lebih lanjut.`;
          break;
        case "USER_BANNED":
          description = t`Akun saweriamu telah dinonaktifkan (banned) oleh sistem.`;
          break;
        default:
          description = t`Ada kesalahan pada sistem kami`;
          break;
      }
      toast({
        title: title,
        description: description,
        isClosable: true,
        status: status,
      });
    },
  });

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        otp: "",
      }}
      validationSchema={getFormValidation(needOTP)}
      onSubmit={submitLogin}
    >
      {({ isSubmitting, values }) => {
        return (
          <Form method="POST">
            <Field type="text" name="email">
              {({ field, form }) => {
                return (
                  <FormControl
                    isRequired
                    mb={3}
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel htmlFor="email">Email:</FormLabel>
                    <Input
                      data-testid="email-form"
                      {...field}
                      id="email"
                      placeholder="email@pribadi.com"
                      autoFocus={!needOTP}
                    />
                    <FormErrorMessage>
                      {getFormError(form.errors.email, _)}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            </Field>
            <Field type="password" name="password">
              {({ field, form }) => {
                return (
                  <FormControl
                    isRequired
                    mb={3}
                    isInvalid={form.errors.password && form.touched.password}
                  >
                    <FormLabel htmlFor="password">Password:</FormLabel>
                    <PasswordInput
                      data-testid="password-form"
                      {...field}
                      id="password"
                    />
                    <FormErrorMessage>
                      {getFormError(form.errors.password, _)}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            </Field>
            {needOTP ? (
              <Field type="otp" name="otp">
                {({ field, form }) => {
                  return (
                    <FormControl
                      isRequired
                      mb={3}
                      isInvalid={form.errors.otp && form.touched.otp}
                    >
                      <FormLabel htmlFor="otp">2FA Token:</FormLabel>
                      <Input
                        data-testid="otp-check"
                        type="text"
                        {...field}
                        placeholder="000000"
                        autoFocus
                        id="otp"
                      />
                      <FormErrorMessage>
                        {getFormError(form.errors.otp, _)}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>
            ) : null}

            <SimpleGrid columns={"2"} spacing="1em" alignItems="center">
              {needOTP ? (
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  type="button"
                  onClick={() => {
                    disableTOTP(values);
                  }}
                  label="Matikan 2FA"
                />
              ) : (
                <Link fontSize="m" href="/reset-password">
                  <Trans>Lupa Password</Trans>
                </Link>
              )}
              <Button
                backgroundColor="saweria-green.normal"
                label={t`Masuk`}
                data-testid="submit-login"
                loading={isSubmitting}
                type="Masuk"
              ></Button>
            </SimpleGrid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
