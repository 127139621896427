import React from "react";

import {
  FormControl,
  Stack,
  Text,
  Button,
  VStack,
  GridItem,
  SimpleGrid,
  HStack,
  IconButton,
  Flex,
  Checkbox,
  Center,
} from "@chakra-ui/react";
import { FaPlay } from "react-icons/fa";

import { formatMoney } from "../../../utils";

const SoundItem = ({
  caption,
  price,
  soundFile,
  mode,
  selectedSoundboardItem,
  setSelectedSoundboardItem,
  setFieldValue,
  currency,
  item,
  lastAudio,
  setLastAudio,
}) => {
  const play = (soundUrl) => {
    const audio = new Audio(soundUrl);
    if (lastAudio) {
      lastAudio.pause();
    }
    setLastAudio(audio);
    audio.play();
  };

  const isSelected = () => {
    const isSameId = selectedSoundboardItem
      ? selectedSoundboardItem.id === item.id
      : false;

    return isSameId;
  };

  const { id } = item;

  const updateAmount = () => {
    if (mode === "tipper") {
      setFieldValue("soundboard", { id });
      setFieldValue("amount", price);
      setFieldValue("mediaType", "sb");
    }
  };

  const onClick = () => {
    updateAmount();
    if (setSelectedSoundboardItem) {
      if (isSelected()) {
        setSelectedSoundboardItem(null);
      } else {
        setSelectedSoundboardItem(item);
      }
    }
  };

  React.useEffect(() => {
    if (isSelected()) {
      updateAmount();
    }
  }, []);

  const vStackProps = isSelected()
    ? {
        transform: "translate(0.1rem, 0.1rem)",
        boxShadow: "pressed",
        backgroundColor: "saweria-green.normal",
      }
    : { boxShadow: "buttonRaised", backgroundColor: "saweria-gray.normal" };

  return (
    <VStack
      border={"1px"}
      {...vStackProps}
      borderRadius={5}
      paddingBottom={2}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      cursor={"pointer"}
      verticalAlign={"center"}
    >
      <HStack justifyContent={"center"} paddingLeft={2} paddingRight={2}>
        <Text noOfLines={1} fontWeight={"bold"}>
          {caption}
        </Text>
        <IconButton
          variant="unstyled"
          justifyItems={"center"}
          icon={
            <Center>
              {" "}
              <FaPlay />
            </Center>
          }
          onClick={() => play(soundFile)}
        />
      </HStack>
      <Text fontSize={{ base: "11px", sm: "12px", md: "14px" }}>
        {formatMoney(price, { currency })}
      </Text>

      {mode === "tipper" ? null : (
        <Checkbox isChecked={isSelected()}>Edit</Checkbox>
      )}
    </VStack>
  );
};

const SoundboardSelector = ({
  setFieldValue,
  soundboardData,
  currency,
  mode,
  selectedSoundboardItem,
  setSelectedSoundboardItem,
}) => {
  const [lastAudio, setLastAudio] = React.useState(null);

  return (
    <>
      <Stack columns="2" spacing="8">
        <FormControl width="100%">
          <SimpleGrid columns={{ base: 2, sm: 4 }} spacing={4}>
            {soundboardData.map((sb, index) => {
              return (
                <GridItem key={`${sb.caption}_${index}`}>
                  <SoundItem
                    lastAudio={lastAudio}
                    setLastAudio={setLastAudio}
                    mode={mode}
                    currency={currency}
                    item={sb}
                    setFieldValue={setFieldValue}
                    selectedSoundboardItem={selectedSoundboardItem}
                    setSelectedSoundboardItem={setSelectedSoundboardItem}
                    caption={sb.name}
                    price={sb.price}
                    soundFile={sb.fileUrl}
                  />
                </GridItem>
              );
            })}
          </SimpleGrid>
        </FormControl>
      </Stack>
    </>
  );
};

export { SoundboardSelector };
