import React from "react";
import {
  Box,
  Flex,
  IconButton,
  ButtonGroup,
  Avatar,
  Skeleton,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Button as ChakraButton,
  useTab,
} from "@chakra-ui/react";
import { Large, P } from "../components/Type";
import { DonationForm } from "../views/DonationForm";
import { Dialog } from "../components/Dialog";
import { _get } from "../utils/request";
import Head from "../components/Head";
import { Footer } from "../components/Footer";
import Button from "../components/Button";
import { Announcement } from "../components/Announcement";
import {
  FaTwitch,
  FaYoutube,
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaCheck,
} from "react-icons/fa";
import { useRouter } from "next/router";
import Image from "next/image";
import { RacoonSad, DefaultCharacter, DoggoError } from "../assets";
import { LoginHelperModal } from "../views/StreamerPage/LoginHelper";
import { useUserInfo } from "../hooks/useUserInfo";
import { Profile } from "../components/AppShell";
import dynamic from "next/dynamic";
import { loadCatalog } from "../i18n";
import { msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { SoundboardForm } from "../views/SoundboardForm";

const Snow = dynamic(
  () =>
    import("../components/Snow").then((mod) => {
      return mod.Snow;
    }),
  { ssr: false }
);

const SingleCardPage = dynamic(
  () =>
    import("../components/SingleCardPage").then((mod) => mod.SingleCardPage),
  { ssr: false }
);

const getPaymentFlags = () => {
  const isGopayEnabled = (process.env.gopay ?? "1") === "1";
  const isOvoEnabled = (process.env.ovo ?? "1") === "1";
  const isDanaEnabled = (process.env.dana ?? "1") === "1";
  const isLinkAjaEnabled = (process.env.linkaja ?? "1") === "1";
  const isQRISEnabled = (process.env.qris ?? "1") === "1";
  return {
    isGopayEnabled,
    isOvoEnabled,
    isDanaEnabled,
    isLinkAjaEnabled,
    isQRISEnabled,
  };
};

export async function getStaticPaths() {
  return { paths: [], fallback: true };
}

export async function getStaticProps({ params, ...ctx }) {
  const translation = await loadCatalog(ctx.locale);

  const revalidate = { revalidate: 5 };
  const paymentFlags = getPaymentFlags();
  const { username } = params;
  try {
    const {
      json: { data: apiData },
    } = await _get(`users/${username}`);
    const translation = await loadCatalog(
      apiData.currency === "IDR" ? "id" : "en"
    );

    return {
      props: { data: { ...apiData, ...paymentFlags }, translation },
      ...revalidate,
    };
  } catch (error) {
    if (error.response) {
      const { status, json } = error.response;
      return {
        props: {
          data: { username },
          error: { json, status },
          translation,
        },
        ...revalidate,
      };
    }
    return {
      props: {
        data: {
          username,
        },
        translation,
        error: { message: "UNKNOWN_ERROR", status: 500 },
      },
      ...revalidate,
    };
  }
}

const DonatePage = ({ data = {}, error, translation }) => {
  const { isFallback } = useRouter();
  const { data: userInfo = {} } = useUserInfo({}, () => {});
  const { username: currUsername } = userInfo;
  const [mode, setMode] = React.useState();

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <Head title={`Memproses`}></Head>
      <Announcement />
      <Flex direction="column" alignItems="center" minH="100vh">
        <Flex
          flexGrow={1}
          w={["100%", "100%", "45%"]}
          minW={["", "", "720px"]}
          paddingRight={["3", "3", 0]}
          paddingLeft={["1", "3", 0]}
          direction="column"
          justifyContent="center"
        >
          <Flex mt={"1em"} direction="row" justifyContent="end">
            {currUsername ? (
              <Profile
                profilePicture={userInfo?.profile_picture}
                username={userInfo?.username}
              />
            ) : (
              <Button
                disabled={isFallback}
                bgColor="saweria-green.normal"
                label="Login"
                onClick={() => setMode("login")}
              />
            )}
          </Flex>

          <DonationPageContent
            data={data}
            error={error}
            isFallback={isFallback}
            userInfo={userInfo}
            // for login
            mode={mode}
            setMode={setMode}
          />

          <Footer marginTop={4}></Footer>
        </Flex>
      </Flex>
    </>
  );
};

const ErrorMessage = ({ error }) => {
  const { status: errorStatusCode, json: response = {} } = error;
  const { _ } = useLingui();

  if (errorStatusCode) {
    const { message } = response;
    let label = "Hmm ada yang salah dengan layanan kami";
    let title = "Uh Oh";
    let image = RacoonSad;

    switch (message) {
      case "NOT_ACTIVATED":
        title = msg`User belum aktif`;
        label = msg`User belum aktif`;
        break;
      case "USER_SUSPENDED":
        title = "SUSPENDED";
        label = msg`User telah melanggar Syarat dan Ketentuan`;
        break;
      case "USER_BANNED":
        title = "BANNED";
        label = msg`User telah melanggar Syarat dan Ketentuan`;
        break;
      case "User does not exist":
        title = msg`User tidak ditemukan`;
        label = msg`User tidak ditemukan`;
        image = DoggoError;
        break;
    }

    return (
      <SingleCardPage
        backgroundColor="saweria-purple.light"
        imageSrc={image}
        message={_(label)}
        title={_(title)}
      />
    );
  }
};

const DonationPageContent = ({
  data,
  error,
  isFallback,
  userInfo = {},
  mode,
  setMode,
}) => {
  const {
    username,
    id,
    description,
    plus,
    overlay = {},
    vote = {},
    socials = [],
    profile_picture,
    verified,
    isGopayEnabled,
    isOvoEnabled,
    isDanaEnabled,
    isLinkAjaEnabled,
    isQRISEnabled,
    currency,
    transaction_fee_policy: transactionFeePolicy,
  } = data;

  const { soundboard } = overlay;

  const paymentFlags = {
    isGopayEnabled,
    isOvoEnabled,
    isDanaEnabled,
    isLinkAjaEnabled,
    isQRISEnabled,
  };

  const { username: currUsername, email: currEmail } = userInfo;
  const [tabIndex, setTabIndex] = React.useState(0);

  const { _ } = useLingui();

  const tabCaptions = [msg`Dukungan`, msg`Soundboard`];

  const CustomTab = React.forwardRef((props, ref) => {
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps["aria-selected"];
    const { caption } = tabProps;

    const buttonProps = isSelected
      ? {
          boxShadow: "pressed",
          backgroundColor: "saweria-yellow.normal",
          variant: "solid",
        }
      : {
          backgroundColor: "saweria-gray.normal",
          variant: "outline",
        };

    return (
      <Button
        margin={2}
        {...buttonProps}
        type="button"
        {...tabProps}
        label={caption}
      ></Button>
    );
  });

  return (
    <>
      {!isFallback ? (
        <LoginHelperModal mode={mode} setMode={setMode} userInfo={userInfo} />
      ) : null}
      <Flex direction={["column", "row", "row"]} alignItems="center" mb={3}>
        {profile_picture ? (
          <Avatar
            w={["120px", "100px", "150px"]}
            h={["120px", "100px", "150px"]}
            size="2xl"
            m={5}
            name={username.toUpperCase()}
            src={profile_picture}
          />
        ) : (
          <Box w={["50%", "30%"]} flexShrink="0" mb={[3, 0]} mt={3} mr={[0, 3]}>
            <Image alt="Saweria Logo" src={DefaultCharacter}></Image>
          </Box>
        )}
        <Box textAlign={["center", "left"]} w="100%">
          {isFallback ? (
            <Skeleton height={"20px"} w="100%" />
          ) : (
            <Large>
              <span style={{ whiteSpace: "nowrap" }}>
                {username}
                {verified ? (
                  <IconButton
                    title="Verified"
                    ml={2}
                    size="xs"
                    variant="solid"
                    colorScheme="blue"
                    aria-label="Verified"
                    icon={<FaCheck />}
                  />
                ) : null}
              </span>
            </Large>
          )}
          <SocialLinks socials={socials} />
          <P>{description}</P>
        </Box>
      </Flex>
      <Dialog marginBottom={3} minHeight="60vh">
        {isFallback ? (
          <Flex
            h="50vh"
            alignItems="center"
            justifyContent="space-evenly"
            direction="column"
          >
            <Skeleton h="20px" my="1em" w="100%" />
            <Skeleton h="20px" my="1em" w="100%" />
            <Skeleton h="20px" my="1em" w="100%" />
            <Skeleton h="20px" my="1em" w="100%" />
            <Skeleton h="20px" my="1em" w="100%" />
            <Skeleton h="20px" my="1em" w="100%" />
          </Flex>
        ) : (
          <>
            <Head title={t`Dukung ${username}`}>
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content="@saweriaco" />
              <meta
                name="twitter:title"
                content={t`Dukung ${username} via saweria`}
              />
              <meta name="twitter:description" content={description} />
              <meta
                name="twitter:image"
                content="https://saweria.co/twitter_card.png"
              ></meta>
            </Head>

            {soundboard ? (
              <Tabs
                variant="soft-rounded"
                onChange={(index) => setTabIndex(index)}
              >
                <TabList>
                  {tabCaptions.map((caption, index) => {
                    return <CustomTab key={caption} caption={_(caption)} />;
                  })}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <DonationForm
                      plus={plus}
                      overlay={overlay}
                      vote={vote}
                      streamerId={id}
                      {...paymentFlags}
                      currEmail={currEmail}
                      currUsername={currUsername}
                      currency={currency}
                      transactionFeePolicy={transactionFeePolicy}
                    />
                  </TabPanel>
                  <TabPanel>
                    <SoundboardForm
                      plus={plus}
                      overlay={overlay}
                      vote={vote}
                      streamerId={id}
                      {...paymentFlags}
                      currEmail={currEmail}
                      currUsername={currUsername}
                      currency={currency}
                      transactionFeePolicy={transactionFeePolicy}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            ) : (
              <DonationForm
                plus={plus}
                overlay={overlay}
                vote={vote}
                streamerId={id}
                {...paymentFlags}
                currEmail={currEmail}
                currUsername={currUsername}
                currency={currency}
                transactionFeePolicy={transactionFeePolicy}
              />
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

const socialIcon = {
  facebook: <FaFacebookF />,
  instagram: <FaInstagram />,
  twitch: <FaTwitch />,
  twitter: <FaTwitter />,
  youtube: <FaYoutube />,
};

const SocialLinks = ({ socials }) => {
  return (
    <Flex justify={["center", "left"]} mb={3}>
      <ButtonGroup spacing={0}>
        {Object.keys(socials)
          .filter((s) => socials[s])
          .map((s, i) => (
            <IconButton
              key={i}
              as="a"
              target="_blank"
              href={`https://${s}.com/${socials[s]}`}
              isRound
              size="md"
              variant="ghost"
              colorScheme="gray"
              aria-label={s}
              fontSize="25px"
              icon={socialIcon[s]}
            />
          ))}
      </ButtonGroup>
    </Flex>
  );
};

export default DonatePage;
